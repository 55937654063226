import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Spinner, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
export const AddNoticeSet = () => {
  const [materials, setMaterials] = useState([]);
  const [materialQuery, setMaterialQuery] = useState();
  const [materialloading, setMaterialloading] = useState(false);
  const { handleSubmit, register, watch } = useForm();
  const { user } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.language);
  const history = useHistory();
  const getMaterials = useCallback(async () => {
    setMaterialloading(true);
    try {
      const { data } = await axios.get(`/admin/getmaterials/${user.id}`);
      // console.log(data.materials);

      if (data.success) {
        setMaterials(data.materials);
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
    setMaterialloading(false);
  }, [user.id]);

  const addNoticeSet = async (values) => {
    try {
      const { data } = await axios.post(
        `/admin/addnoticeset/${user.organization}`,
        values
      );
      if (data.success) {
        history.goBack();
      }
      console.log(data.message);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getMaterials();
  }, [getMaterials]);
  return (
    <Container>
      <Row lg={1} md={1} sm={1} xl={1} xs={1}>
        <Col style={{ marginTop: "20px" }}>
          <Form onSubmit={handleSubmit(addNoticeSet)}>
            <h1>
              {language === "english" ? "Add a notice" : "নোটিশ যোগ করুন"}
            </h1>
            <Form.Group>
              <Form.Label>
                {language === "english"
                  ? "Notice Set Name"
                  : "নোটিশের নাম লিখুন"}
              </Form.Label>
              <Form.Control
                name="name"
                ref={register({
                  required: "You must input a notice set name",
                })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                {language === "english"
                  ? "Select notice display type"
                  : "নোটিশের ধরণ বাছাই করুন"}
              </Form.Label>
              <Form.Control
                as="select"
                defaultValue="singlecontent"
                name="viewtype"
                ref={register({
                  required: "You must select a notice display type",
                })}
              >
                <option value="singlecontent">Single content</option>
                <option value="imagetextslider">Slider</option>
                {/* <option value="videoslider">Single content</option> */}
                {/* <option value="grid">Grid</option> */}
                {/* <option value="gridslider">Grid Slider</option> */}
              </Form.Control>
            </Form.Group>
            {watch("viewtype") !== "singlecontent" && (
              <Form.Group>
                <Form.Label>
                  {language === "english"
                    ? "Set slide interval in seconds"
                    : "স্লাইড পরিবর্তনের সময় বাছাই করুন"}
                </Form.Label>
                <Form.Control
                  name="interval"
                  type="number"
                  ref={register({
                    required: "You must input notice slider interval",
                  })}
                />
              </Form.Group>
            )}
            <Form.Group>
              {materialloading ? (
                <Spinner animation="border" />
              ) : (
                <>
                  <Form.Label>
                    {language === "english"
                      ? "Select contents for notice"
                      : "নোটিশের কনটেন্ট বাছাই করুন"}
                  </Form.Label>
                  <Form.Control
                    onChange={(e) => setMaterialQuery(e.target.value)}
                    placeholder={
                      language === "english"
                        ? "Search materials here"
                        : "এখানে কন্টেন্ট খুজুন"
                    }
                  />
                  <div style={{ maxHeight: "200px", overflow: "auto" }}>
                    {materials
                      ?.filter((material) => {
                        if (watch("viewtype") === "imagetextslider") {
                          return material.materialtype !== "Video";
                        } else {
                          return material;
                        }
                      })
                      ?.filter((material) => {
                        return (
                          material.name
                            ?.toLowerCase()
                            .includes(materialQuery?.toLowerCase()) ||
                          !materialQuery
                        );
                      })
                      .map((material, id) => (
                        <Form.Check
                          disabled={materialloading}
                          key={id}
                          type="checkbox"
                          id="materials"
                          name="materials"
                          label={material.name}
                          value={material._id}
                          ref={register}
                        />
                      ))}
                  </div>
                </>
              )}
            </Form.Group>

            {/* } */}
            <Button
              type="button"
              variant="secondary"
              onClick={() => history.goBack()}
            >
              {language === "english" ? "Cancel" : "বাতিল"}
            </Button>
            <Button type="submit" variant="primary">
              {language === "english" ? "Submit Notice" : "নোটিশ সাবমিট করুন"}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

import { CHANGE_TO_BANGLA, CHANGE_TO_ENGLISH } from "../type/types";
const initialState = { language: "english" };
const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TO_BANGLA:
      return action.payload;
    case CHANGE_TO_ENGLISH:
      return action.payload;
    default:
      return state;
  }
};

export default languageReducer;

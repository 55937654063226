import React from "react";
import { Container } from "react-bootstrap";
// import DiskSpace from "../components/DashComponents/DiskSpace";
import NoticeBoards from "../components/DashComponents/NoticeBoards";

export default function DashBoard() {
  return (
    <Container>
      <NoticeBoards />
    </Container>
  );
}

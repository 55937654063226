import { Component } from "react"

function logErrorToServer(errorStack) {
    const errors = { error: JSON.stringify(errorStack, null, 2), timestamp: new Date().toLocaleString() }
    console.log(errors)
}

export default class ErrorBoundary extends Component {
    componentDidCatch(error, errorInfo) {
        logErrorToServer(error, errorInfo);
    }

    render() {
        return this.props.children;
    }
}

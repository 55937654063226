import React, { useState, useEffect, useCallback } from "react";
import { Container, Form, Button, Nav, Spinner } from "react-bootstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SplitSet } from "../components/DashComponents/SplitSet";
// import ThreeThree from "../assets/threethree.png";
// import ThreeTwo from "../assets/threetwo.png";
import onedisplay from "../assets/one-display.png";
import twosplit from "../assets/two-split.png";
import threetworight from "../assets/three-two-right.png";
import threetwoleft from "../assets/three-two-left.png";
import threesplit from "../assets/three-split.png";
import foursplit from "../assets/four-split.png";
import socketClientConnection from "../utils/socketClientConnection";

export default function EditNoticeBoard({ match }) {
  const { REACT_APP_NOT_AXIOS_BASE_URL } = process.env;
  const [noticeboard, setNoticeboard] = useState({});

  const [loading, setLoading] = useState(false);
  const [loadingToggleClock, setLoadingToggleClock] = useState(
    noticeboard.clock || false
  );
  console.log(noticeboard);
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const updateData = (formData) => {
    socketClientConnection.emit("updatedata", formData);
  };
  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      headline: noticeboard.headline,
    },
  });

  const getNoticeBoard = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/admin/getnoticeboard/${match.params.id}`
      );
      console.log(data);
      if (data.success) {
        setNoticeboard(data.noticeboard);
      }
    } catch (error) {
      console.log(error.message);
    }
    setLoading(false);
  }, [match.params.id]);
  const [boardLayout, setBoardLayout] = useState(noticeboard.splitType);
  const handleBoardLayout = (evk) => {
    setBoardLayout(evk);
  };

  const toggleClock = async (e) => {
    setLoadingToggleClock(true);
    try {
      const { data } = await axios.post(
        `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/toggle/clock/${noticeboard._id}`,
        {
          clock: !noticeboard?.clock,
        }
      );
      if (data.success) {
        getNoticeBoard();
      } else {
        console(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
    setLoadingToggleClock(false);
  };

  const { language } = useSelector((state) => state.language);

  const setNoticeSetForBoard = async (values) => {
    try {
      const { data } = await axios.post(
        `/admin/editnoticeboard/setnoticeset/${noticeboard?._id}`,
        {
          splitType: boardLayout,
          splitNoticeSets: values.splitNoticeSets,
          headline: watch("hasHeadline")
            ? values.headline || noticeboard.heaadline
            : "",
          headlineTwo: watch("hasHeadlineTwo")
            ? values.headlineTwo || noticeboard.headlineTwo
            : "",
        }
      );
      if (data.success) {
        getNoticeBoard();
        let formdata = {
          id: noticeboard._id,
        };
        updateData(formdata);
        history.goBack();
      }
      // console.log(data.message);
      // console.log({
      //   splitType: values.splitType,
      //   splitNoticeSets: values.splitNoticeSets,
      // });
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getNoticeBoard();
  }, [getNoticeBoard]);
  useEffect(() => {
    socketClientConnection.on("connect", () => {
      socketClientConnection.emit("connected", { type: "admin", id: user.id });
    });
  }, [user.id]);
  useEffect(() => {
    setBoardLayout(noticeboard.splitType);
  }, [noticeboard]);
  return (
    <Container>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Form
          onSubmit={handleSubmit(setNoticeSetForBoard)}
          className="row mx-auto"
        >
          <div className="col-12 my-3">
            <Button onClick={() => history.push("/addnoticesets")}>
              {language === "english"
                ? "Add a new Notice Set"
                : "নতুন নোটিশ যোগ করুন"}
            </Button>
          </div>
          <div className="col-12 my-3">
            <h1>
              {language === "english" ? "Select layout" : "লেআউট বাছাই করুন"}
            </h1>

            <Nav
              variant="pills"
              className="boardlayoutnav"
              defaultActiveKey={noticeboard?.splitType}
            >
              <Nav.Item>
                <Nav.Link onSelect={handleBoardLayout} eventKey="onedisplay">
                  <img
                    src={onedisplay}
                    alt="One full display"
                    className="layoutbuttonsicon"
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onSelect={handleBoardLayout} eventKey="twosplit">
                  <img
                    src={twosplit}
                    alt="Two split display"
                    className="layoutbuttonsicon"
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onSelect={handleBoardLayout} eventKey="threetworight">
                  <img
                    src={threetworight}
                    alt="Three-two split right display"
                    className="layoutbuttonsicon"
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onSelect={handleBoardLayout} eventKey="threetwoleft">
                  <img
                    src={threetwoleft}
                    alt="Three-two split left display"
                    className="layoutbuttonsicon"
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onSelect={handleBoardLayout} eventKey="threesplit">
                  <img
                    src={threesplit}
                    alt="Three split display"
                    className="layoutbuttonsicon"
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onSelect={handleBoardLayout} eventKey="foursplit">
                  <img
                    src={foursplit}
                    alt="Four split display"
                    className="layoutbuttonsicon"
                  />
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <>
              {boardLayout === "onedisplay" && (
                <div className="">
                  <SplitSet
                    name={`splitNoticeSets[0]`}
                    register={register}
                    noticeset={noticeboard?.splitNoticeSets}
                    part={0}
                  />
                </div>
              )}
              {boardLayout === "twosplit" && (
                <div className="row">
                  <div className="col">
                    <SplitSet
                      name={`splitNoticeSets[0]`}
                      register={register}
                      noticeset={noticeboard?.splitNoticeSets}
                      part={0}
                    />
                  </div>
                  <div className="col">
                    <SplitSet
                      name={`splitNoticeSets[1]`}
                      register={register}
                      noticeset={noticeboard?.splitNoticeSets}
                      part={1}
                    />
                  </div>
                </div>
              )}
              {boardLayout === "threetworight" && (
                <div className="row">
                  <div className="col">
                    <SplitSet
                      name={`splitNoticeSets[0]`}
                      register={register}
                      noticeset={noticeboard?.splitNoticeSets}
                      part={0}
                    />
                  </div>
                  <div className="col row">
                    <div className="col-12 mr-1 mb-3">
                      <SplitSet
                        name={`splitNoticeSets[1]`}
                        register={register}
                        noticeset={noticeboard?.splitNoticeSets}
                        part={1}
                      />
                    </div>
                    <div className="col-12 mr-1 mt-3">
                      <SplitSet
                        name={`splitNoticeSets[2]`}
                        register={register}
                        noticeset={noticeboard?.splitNoticeSets}
                        part={2}
                      />
                    </div>
                  </div>
                </div>
              )}
              {boardLayout === "threetwoleft" && (
                <div className="row">
                  <div className="col row">
                    <div className="col-12 mr-1 mb-3">
                      <SplitSet
                        name={`splitNoticeSets[0]`}
                        register={register}
                        noticeset={noticeboard?.splitNoticeSets}
                        part={0}
                      />
                    </div>
                    <div className="col-12 mr-1 mt-3">
                      <SplitSet
                        name={`splitNoticeSets[1]`}
                        register={register}
                        noticeset={noticeboard?.splitNoticeSets}
                        part={1}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <SplitSet
                      name={`splitNoticeSets[2]`}
                      register={register}
                      noticeset={noticeboard?.splitNoticeSets}
                      part={2}
                    />
                  </div>
                </div>
              )}
              {boardLayout === "threesplit" && (
                <div className="row">
                  <div className="col-4">
                    <SplitSet
                      name={`splitNoticeSets[0]`}
                      register={register}
                      noticeset={noticeboard?.splitNoticeSets}
                      part={0}
                    />
                  </div>
                  <div className="col-4">
                    <SplitSet
                      name={`splitNoticeSets[1]`}
                      register={register}
                      noticeset={noticeboard?.splitNoticeSets}
                      part={1}
                    />
                  </div>
                  <div className="col-4">
                    <SplitSet
                      name={`splitNoticeSets[2]`}
                      register={register}
                      noticeset={noticeboard?.splitNoticeSets}
                      part={2}
                    />
                  </div>
                </div>
              )}

              {boardLayout === "foursplit" && (
                <div className="row">
                  <div className="col-6 mb-3">
                    <SplitSet
                      name={`splitNoticeSets[0]`}
                      register={register}
                      noticeset={noticeboard?.splitNoticeSets}
                      part={0}
                    />
                  </div>
                  <div className="col-6  mb-3">
                    <SplitSet
                      name={`splitNoticeSets[1]`}
                      register={register}
                      noticeset={noticeboard?.splitNoticeSets}
                      part={1}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <SplitSet
                      name={`splitNoticeSets[2]`}
                      register={register}
                      noticeset={noticeboard?.splitNoticeSets}
                      part={2}
                    />
                  </div>
                  <div className="col-6  mt-3">
                    <SplitSet
                      name={`splitNoticeSets[3]`}
                      register={register}
                      noticeset={noticeboard?.splitNoticeSets}
                      part={3}
                    />
                  </div>
                </div>
              )}
            </>
          </div>
          <div className="col-12 mt-2">
            <div className="form-check">
              <label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={noticeboard?.headline}
                  name="hasHeadline"
                  ref={register}
                />
                <p>
                  {language === "english"
                    ? "Has a headline?"
                    : "হেডলাইন থাকবে?"}
                </p>
              </label>
            </div>
          </div>
          {watch("hasHeadline") ? (
            <div className="col-12 mt-2">
              <label>
                {language === "english" ? "Add a headline" : "হেডলাইন যোগ করুন"}
              </label>
              <textarea
                className="form-control"
                name="headline"
                rows={4}
                defaultValue={noticeboard?.headline}
                ref={register}
              />
            </div>
          ) : (
            ""
          )}
          <div className="col-12 mt-2">
            <div className="form-check">
              <label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={noticeboard?.headline}
                  name="hasHeadlineTwo"
                  ref={register}
                />
                <p>
                  {language === "english"
                    ? "Has a second headline?"
                    : "হেডলাইন থাকবে?"}
                </p>
              </label>
            </div>
          </div>
          {watch("hasHeadlineTwo") ? (
            <div className="col-12 my-2">
              <label>
                {language === "english"
                  ? "Add a seond headline"
                  : "হেডলাইন যোগ করুন"}
              </label>
              <textarea
                className="form-control"
                name="headlineTwo"
                rows={4}
                defaultValue={noticeboard?.headlineTwo}
                ref={register}
              />
            </div>
          ) : (
            ""
          )}
          <div className="col-12 mt-2">
            <Button
              type="button"
              onClick={() => history.goBack()}
              variant="outline-danger"
            >
              {language === "english" ? "Cancel" : "বাতিল"}
            </Button>{" "}
            <Button type="submit" variant="primary">
              {language === "english" ? "Update" : "আপডেট করুন"}
            </Button>
          </div>
        </Form>
      )}
      <Button
        type="button"
        className="m-2"
        onClick={toggleClock}
        disabled={loadingToggleClock}
      >
        Turn {noticeboard.clock ? "off" : "on"} Clock
      </Button>
    </Container>
  );
}

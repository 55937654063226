import { Button } from "react-bootstrap";

// ... Your existing code

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const visiblePages = 5; // Adjust this value as needed

  const renderPages = () => {
    const pages = [];

    if (totalPages <= visiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <Button
            key={i}
            variant={i === currentPage ? "primary" : "secondary"}
            className="mx-1"
            onClick={() => onPageChange(i)}
          >
            {i}
          </Button>,
        );
      }
    } else {
      const halfVisible = Math.floor(visiblePages / 2);
      const startPage = Math.max(1, currentPage - halfVisible);
      const endPage = Math.min(totalPages, startPage + visiblePages - 1);

      if (startPage > 1) {
        pages.push(
          <Button
            key="first"
            variant="secondary"
            className="mx-1"
            onClick={() => onPageChange(1)}
          >
            1
          </Button>,
          <Button key="ellipsis1" variant="secondary" disabled className="mx-1">
            ...
          </Button>,
        );
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <Button
            key={i}
            variant={i === currentPage ? "primary" : "secondary"}
            className="mx-1"
            onClick={() => onPageChange(i)}
          >
            {i}
          </Button>,
        );
      }

      if (endPage < totalPages) {
        pages.push(
          <Button key="ellipsis2" variant="secondary" disabled className="mx-1">
            ...
          </Button>,
          <Button
            key="last"
            variant="secondary"
            className="mx-1"
            onClick={() => onPageChange(totalPages)}
          >
            {totalPages}
          </Button>,
        );
      }
    }

    return pages;
  };

  return <div className="d-flex justify-content-center">{renderPages()}</div>;
};

export default Pagination;

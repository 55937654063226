import { CHANGE_TO_BANGLA, CHANGE_TO_ENGLISH } from "../type/types";
// Set logged in user
export const setLanguageToEnglish = () => {
  return {
    type: CHANGE_TO_ENGLISH,
    payload: { language: "english" },
  };
};
export const setLanguageToBangla = () => {
  return {
    type: CHANGE_TO_BANGLA,
    payload: { language: "bangla" },
  };
};

import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import DiskSpace from "../components/DashComponents/DiskSpace";
import socketClientConnection from "../utils/socketClientConnection";
export default function Setting() {
  const { REACT_APP_NOT_AXIOS_BASE_URL } = process.env;

  const [updateOrgLogo, setUpdateOrgLogo] = useState(false);
  const [updateOrgName, setUpdateOrgName] = useState(false);
  const [updateOrgAltText, setUpdateOrgAltText] = useState(false);
  const [updateExtraLogo, setUpdateExtraLogo] = useState(false);
  const [updateOrgHeadlineTwo, setUpdateOrgHeadlineTwo] = useState(false);
  const [updateOrgHeadline, setUpdateOrgHeadline] = useState(false);

  const [updateOrgHeader, setUpdateOrgHeader] = useState(false);

  const [file, setFile] = useState();
  const [extraFile, setExtraFile] = useState();
  const [name, setName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgAltText, setOrgAltText] = useState("");
  const [orgLogo, setOrgLogo] = useState("");
  const [orgHeaderColor, setOrgHeaderColor] = useState("");
  const [orgHeaderTextColor, setOrgHeaderTextColor] = useState("");
  const [orgHeadlineColor, setOrgHeadlineColor] = useState("");
  const [orgHeadlineTextColor, setOrgHeadlineTextColor] = useState("");
  const [orgHeadlineTwoColor, setOrgHeadlineTwoColor] = useState("");
  const [orgHeadlineTwoTextColor, setOrgHeadlineTwoTextColor] = useState("");
  const [extraLogo, setExtraLogo] = useState("");

  const { user } = useSelector((state) => state.auth);
  const updateAllData = (formData) => {
    socketClientConnection.emit("updatealldata", formData);
  };
  let orgdata = {
    id: user?.organization,
  };
  const changeName = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/changeorgname/${user?.organization}`,
        {
          name: name,
        }
      );
      if (data.success) {
        getOrgName();
        setUpdateOrgName(false);
        updateAllData(orgdata);
      } else {
        console(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const changeOrgAltText = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/changeorgalttext/${user?.organization}`,
        {
          orgalttext: orgAltText,
        }
      );
      if (data.success) {
        getOrgName();
        setUpdateOrgAltText(false);
        updateAllData(orgdata);
      } else {
        console(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const getOrgName = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/getorgname/${user?.organization}`
      );
      console.log(data);
      setOrgName(data.orgname);
      setOrgAltText(data.orgalttext);
      setOrgLogo(data.logo);
      setExtraLogo(data.extra);
      setOrgHeaderColor(data.header.background);
      setOrgHeaderTextColor(data.header.color);
      setOrgHeadlineColor(data.headline.background);
      setOrgHeadlineTextColor(data.headline.color);
      setOrgHeadlineTwoColor(data.headlineTwo.background);
      setOrgHeadlineTwoTextColor(data.headlineTwo.color);
    } catch (error) {
      console.log(error.message);
    }
  }, [REACT_APP_NOT_AXIOS_BASE_URL, user.organization]);
  const UpdateHeadlineStyle = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/headlinestyle/${user?.organization}`,
        {
          background: orgHeadlineColor,
          color: orgHeadlineTextColor,
        }
      );
      console.log(data);
      if (data.success) {
        setUpdateOrgHeadline(false);
        getOrgName();
        updateAllData(orgdata);
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const UpdateHeadlineTwoStyle = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/headlinetwostyle/${user?.organization}`,
        {
          background: orgHeadlineTwoColor,
          color: orgHeadlineTwoTextColor,
        }
      );
      console.log(data);
      if (data.success) {
        setUpdateOrgHeadlineTwo(false);
        getOrgName();
        updateAllData(orgdata);
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const UpdateHeaderStyle = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/headerstyle/${user?.organization}`,
        {
          background: orgHeaderColor,
          color: orgHeaderTextColor,
        }
      );
      console.log(data);
      if (data.success) {
        setUpdateOrgHeader(false);
        getOrgName();
        updateAllData(orgdata);
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const updateLogo = async (e) => {
    e.preventDefault();
    setUpdateOrgLogo(true);
    if (!file) {
      alert("Select a file to upload first");
      setUpdateOrgLogo(false);
      return;
    }
    try {
      let headers = new Headers();
      headers.append("Accept", "Application/JSON");
      headers.append("Access-Control-Allow-Origin", "*");

      let formdata = new FormData();
      formdata.append("file", file);
      formdata.set("orgid", user.organization);

      let req = new Request(
        `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/changelogo/${user?.organization}`,
        {
          method: "POST",
          headers,
          mode: "cors",
          body: formdata,
        }
      );

      fetch(req)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setUpdateOrgLogo(false);
            getOrgName();
            updateAllData(orgdata);
          } else {
            alert(data.message);
          }
        })
        .catch((err) => alert(err.message));
    } catch (error) {
      setUpdateOrgLogo(false);
    }
  };
  const updateExLogo = async (e) => {
    e.preventDefault();
    setUpdateExtraLogo(true);
    if (!extraFile) {
      alert("Select a file to upload first");
      setUpdateOrgLogo(false);
      return;
    }
    try {
      let headers = new Headers();
      headers.append("Accept", "Application/JSON");
      headers.append("Access-Control-Allow-Origin", "*");

      let formdata = new FormData();
      formdata.append("file", extraFile);
      formdata.set("orgid", user.organization);

      let req = new Request(
        `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/changeextralogo/${user?.organization}`,
        {
          method: "POST",
          headers,
          mode: "cors",
          body: formdata,
        }
      );

      fetch(req)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setUpdateExtraLogo(false);
            getOrgName();
            updateAllData(orgdata);
          } else {
            alert(data.message);
          }
        })
        .catch((err) => alert(err.message));
    } catch (error) {
      setUpdateExtraLogo(false);
    }
  };
  useEffect(() => {
    getOrgName();
  }, [getOrgName]);
  return (
    <div className="container">
      <div className="row m-5">
        {/* <div className="col-6">
          <form className="card p-4 m-4">
            <h1>Add an new admin</h1>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Set Username for admin
              </label>
              <input
                className="form-control"
                name="name"
                id="name"
                type="text"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Select NoticeBoards
              </label>
              <input
                className="form-control"
                name="name"
                id="name"
                type="text"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </form>
        </div> */}
        <DiskSpace />
        <div className="col-12">
          <form className="card p-4 m-4" onSubmit={changeName}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Rename your organization
              </label>
              <input
                className="form-control"
                defaultValue={orgName}
                name="name"
                id="name"
                type="text"
                disabled={!updateOrgName}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <button
                type="button"
                className="btn"
                onClick={() => setUpdateOrgName((u) => !u)}
              >
                {updateOrgName ? "Cancel" : "Edit"}
              </button>
              <button type="submit" disabled={!updateOrgName} className="btn">
                Change name
              </button>
            </div>
          </form>
        </div>
        <div className="col-12">
          <form className="card p-4 m-4" onSubmit={changeOrgAltText}>
            <div className="mb-3">
              <label htmlFor="alttext" className="form-label">
                Alternate text for your organization
              </label>
              <input
                className="form-control"
                defaultValue={orgAltText}
                name="alttext"
                id="alttext"
                type="text"
                disabled={!updateOrgAltText}
                onChange={(e) => setOrgAltText(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <button
                type="button"
                className="btn"
                onClick={() => setUpdateOrgAltText((u) => !u)}
              >
                {updateOrgAltText ? "Cancel" : "Edit"}
              </button>
              <button
                type="submit"
                disabled={!updateOrgAltText}
                className="btn"
              >
                Change Alternate text for organization
              </button>
            </div>
          </form>
        </div>
        <div className="col-12">
          <form className="card p-4 m-4" onSubmit={UpdateHeaderStyle}>
            <div className="form-group row">
              <label htmlFor="color-input" className="col col-form-label">
                Header Color
              </label>
              <div className="col">
                {updateOrgHeader ? (
                  <input
                    disabled={!updateOrgHeader}
                    onChange={(e) => setOrgHeaderColor(e.target.value)}
                    className="form-control"
                    type="color"
                    defaultValue={orgHeaderColor}
                    id="color-input"
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "40px",
                      background: orgHeaderColor,
                      border: "1px solid black",
                      borderRadius: "5px",
                    }}
                  ></div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="color-input" className="col col-form-label">
                Header Text Color
              </label>
              <div className="col">
                {updateOrgHeader ? (
                  <input
                    disabled={!updateOrgHeader}
                    onChange={(e) => setOrgHeaderTextColor(e.target.value)}
                    className="form-control"
                    type="color"
                    defaultValue={orgHeaderTextColor}
                    id="color-input"
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "40px",
                      background: orgHeaderTextColor,
                      border: "1px solid black",
                      borderRadius: "5px",
                    }}
                  ></div>
                )}
              </div>
            </div>
            <div className="mb-3">
              <button
                type="button"
                className="btn"
                onClick={() => setUpdateOrgHeader((u) => !u)}
              >
                {updateOrgHeader ? "Cancel" : "Edit"}
              </button>
              <button type="submit" disabled={!updateOrgHeader} className="btn">
                Change Header Color
              </button>
            </div>
          </form>
        </div>
        <div className="col-12">
          <form className="card p-4 m-4" onSubmit={UpdateHeadlineStyle}>
            <div className="form-group row">
              <label htmlFor="color-input" className="col col-form-label">
                Headline Color
              </label>
              <div className="col">
                {updateOrgHeadline ? (
                  <input
                    disabled={!updateOrgHeadline}
                    onChange={(e) => setOrgHeadlineColor(e.target.value)}
                    className="form-control"
                    type="color"
                    defaultValue={orgHeadlineColor}
                    id="color-input"
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "40px",
                      background: orgHeadlineColor,
                      border: "1px solid black",
                      borderRadius: "5px",
                    }}
                  ></div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="color-input" className="col col-form-label">
                Headline Text Color
              </label>
              <div className="col">
                {updateOrgHeadline ? (
                  <input
                    disabled={!updateOrgHeadline}
                    onChange={(e) => setOrgHeadlineTextColor(e.target.value)}
                    className="form-control"
                    type="color"
                    defaultValue={orgHeadlineTextColor}
                    id="color-input"
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "40px",
                      background: orgHeadlineTextColor,
                      border: "1px solid black",
                      borderRadius: "5px",
                    }}
                  ></div>
                )}
              </div>
            </div>
            <div className="mb-3">
              <button
                type="button"
                className="btn"
                onClick={() => setUpdateOrgHeadline((u) => !u)}
              >
                {updateOrgHeadline ? "Cancel" : "Edit"}
              </button>
              <button
                type="submit"
                disabled={!updateOrgHeadline}
                className="btn"
              >
                Change Headline Color
              </button>
            </div>
          </form>
        </div>

        <div className="col-12">
          <form className="card p-4 m-4" onSubmit={UpdateHeadlineTwoStyle}>
            <div className="form-group row">
              <label htmlFor="color-input-two" className="col col-form-label">
                Second Headline Color
              </label>
              <div className="col">
                {updateOrgHeadlineTwo ? (
                  <input
                    disabled={!updateOrgHeadlineTwo}
                    onChange={(e) => setOrgHeadlineTwoColor(e.target.value)}
                    className="form-control"
                    type="color"
                    defaultValue={orgHeadlineTwoColor}
                    id="color-input-two"
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "40px",
                      background: orgHeadlineTwoColor,
                      border: "1px solid black",
                      borderRadius: "5px",
                    }}
                  ></div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="color-input-two" className="col col-form-label">
                Second Headline Text Color
              </label>
              <div className="col">
                {updateOrgHeadlineTwo ? (
                  <input
                    disabled={!updateOrgHeadlineTwo}
                    onChange={(e) => setOrgHeadlineTwoTextColor(e.target.value)}
                    className="form-control"
                    type="color"
                    defaultValue={orgHeadlineTwoTextColor}
                    id="color-input-two"
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "40px",
                      background: orgHeadlineTwoTextColor,
                      border: "1px solid black",
                      borderRadius: "5px",
                    }}
                  ></div>
                )}
              </div>
            </div>
            <div className="mb-3">
              <button
                type="button"
                className="btn"
                onClick={() => setUpdateOrgHeadlineTwo((u) => !u)}
              >
                {updateOrgHeadlineTwo ? "Cancel" : "Edit"}
              </button>
              <button
                type="submit"
                disabled={!updateOrgHeadlineTwo}
                className="btn"
              >
                Change Second Headline Color
              </button>
            </div>
          </form>
        </div>

        <div className="col">
          <form className="card p-4 m-4" onSubmit={updateLogo}>
            <img
              src={orgLogo}
              style={{ height: "200px", width: "200px" }}
              alt=""
            />
            <Form.Group>
              <Form.Label htmlFor="file" className="form-label">
                Choose your organization logo
              </Form.Label>
              <Form.File
                className="form-control"
                name="file"
                id="file"
                type="file"
                accept="image/*"
                disabled={!updateOrgLogo}
                onChange={(e) => setFile(e.target.files[0])}
              />
            </Form.Group>
            <div className="mb-3">
              <button
                type="button"
                className="btn"
                onClick={() => setUpdateOrgLogo((u) => !u)}
              >
                {updateOrgLogo ? "Cancel" : "Edit"}
              </button>
              <button type="submit" disabled={!updateOrgLogo} className="btn">
                Change logo
              </button>
            </div>
          </form>
        </div>
        <div className="col">
          <form className="card p-4 m-4" onSubmit={updateExLogo}>
            <img
              src={extraLogo}
              style={{ height: "200px", width: "200px" }}
              alt=""
            />
            <Form.Group>
              <Form.Label htmlFor="extraFile" className="form-label">
                Choose extra logo
              </Form.Label>
              <Form.File
                className="form-control"
                name="extraFile"
                id="extraFile"
                type="file"
                accept="image/*"
                disabled={!updateExtraLogo}
                onChange={(e) => setExtraFile(e.target.files[0])}
              />
            </Form.Group>
            <div className="mb-3">
              <button
                type="button"
                className="btn"
                onClick={() => setUpdateExtraLogo((u) => !u)}
              >
                {updateExtraLogo ? "Cancel" : "Edit"}
              </button>
              <button type="submit" disabled={!updateExtraLogo} className="btn">
                Change extra logo
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

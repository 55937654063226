import { useState } from "react";
import { FaWindowClose } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  setLanguageToBangla,
  setLanguageToEnglish,
} from "../../redux/actions/languageActions";

const LanguageSwitch = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { language } = useSelector((state) => state.language);
  const onClick = (e) => {
    if (e.target.id === "english") {
      dispatch(setLanguageToEnglish());
    } else {
      dispatch(setLanguageToBangla());
    }
  };
  return (
    <div
      className="d-flex flex-row no-wrap align-items-center"
      style={{
        position: "fixed",
        bottom: "5px",
        right: "5px",
        padding: "3px",
      }}
    >
      {open ? (
        <>
          <div
            style={{
              border: "2px solid #274060ff",
              borderRadius: "5px",
            }}
          >
            <div
              className="btn"
              id="bangla"
              style={{
                background: language === "bangla" ? "#274060ff" : "white",
                color: language === "bangla" ? "white" : "black",
              }}
              onClick={onClick}
            >
              বাংলা
            </div>
            <div
              className="btn"
              id="english"
              style={{
                background: language === "english" ? "#274060ff" : "white",
                color: language === "english" ? "white" : "black",
              }}
              onClick={onClick}
            >
              English
            </div>
          </div>
          <div
            style={{
              cursor: "pointer",
              margin: "3px",
            }}
            onClick={() => setOpen((o) => !o)}
          >
            <FaWindowClose size={28} />
          </div>
        </>
      ) : (
        <div
          className="btn"
          style={{
            background: "#274060ff",
            color: "white",
          }}
          onClick={() => setOpen((o) => !o)}
        >
          {language === "english" ? "EN" : "বা"}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitch;

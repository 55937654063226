import React from "react";
import { MdPermMedia, MdSettings, MdDeveloperBoard } from "react-icons/md";
import { HiOutlineDocumentText, HiLogout } from "react-icons/hi";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logoutUser from "../../redux/actions/authActions";
export default function NavBar() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.language);

  return (
    <Navbar expand="md">
      <Navbar.Brand style={{ fontWeight: "700" }} as={Link} to="/">
        NOTICEBEE
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {isAuthenticated && (
            <>
              <Nav.Link as={Link} to="/">
                <MdDeveloperBoard />
                {language === "english" ? "Noticeboards" : "নোটিশবোর্ড"}
              </Nav.Link>
              <Nav.Link as={Link} to="/notices">
                <HiOutlineDocumentText />
                {language === "english" ? "Notice sets" : "নোটিশ সেট"}
              </Nav.Link>
              <Nav.Link as={Link} to="/materials">
                <MdPermMedia />
                {language === "english"
                  ? "Content Library"
                  : "কনটেন্ট লাইব্রেরি"}
              </Nav.Link>
              <Nav.Link as={Link} to="/settings">
                <MdSettings />
                {language === "english" ? "Settings" : "সেটিংস"}
              </Nav.Link>
              <Nav.Link onClick={() => dispatch(logoutUser())}>
                <HiLogout />
                {language === "english" ? "Logout" : "লগ আউট"}
              </Nav.Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

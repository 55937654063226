import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Form, Col, Button, Spinner, ProgressBar } from "react-bootstrap";
import axios from "axios";
const { REACT_APP_NOT_AXIOS_BASE_URL } = process.env;

const AddMaterial = ({ setAddMaterial }) => {
  const { language } = useSelector((state) => state.language);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState();
  const [progress, setProgress] = useState(0);
  const { user } = useSelector((state) => state.auth);

  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      adminid: user.id,
      materialtype: "Text",
      material: "",
    },
  });
  const materialtype = watch("materialtype");
  const addNewMaterial = async (values) => {
    setLoading(true);
    if (
      materialtype === "Image" ||
      materialtype === "Video" ||
      materialtype === "PDF"
    ) {
      uploadTest(file, values);
    } else {
      try {
        const { data } = await axios.post(
          `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/addmaterial`,
          values
        );
        console.log(data.message);
        if (data.success) {
          setLoading(false);
          setAddMaterial(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    }
  };
  const uploadTest = async (uploadfile, values) => {
    if (!file) {
      alert("Select a file to upload first");
      setLoading(false);
      return;
    }
    try {
      values.orgid = user.organization;
      const uniqueSuffix = uploadfile.name.trim();
      let ext = uniqueSuffix.substring(uniqueSuffix.lastIndexOf(".") + 1);
      values.filename = `File${Math.random().toString(36).slice(2)}.${ext}`;

      const fileReader = new FileReader();
      fileReader.onload = async (ev) => {
        const CHUNK_SIZE = 10000;
        const chunkCount = ev.target.result.byteLength / CHUNK_SIZE;
        console.log(chunkCount);
        for (let chunkId = 0; chunkId < chunkCount + 1; chunkId++) {
          const chunk = ev.target.result.slice(
            chunkId * CHUNK_SIZE,
            chunkId * CHUNK_SIZE + CHUNK_SIZE
          );
          let headers = new Headers();
          headers.append("Accept", "Application/JSON");
          headers.append("Access-Control-Allow-Origin", "*");
          headers.append("content-type", "application/octet-stream");
          headers.append("content-length", chunk.length);
          headers.append("x-values", encodeURIComponent(JSON.stringify(values)));
          if (chunkId + 1 > chunkCount) {
            headers.append("x-last-chunk", "yes");
          } else {
            headers.append("x-last-chunk", "no");
          }
          // let formdata = new FormData();

          // formdata.append("values", JSON.stringify(values));
          // formdata.append("file", chunk);
          // formdata.append(
          //   "last-chunk",
          //   `${chunkId === chunkCount ? "yes" : "no"}`
          // );

          let req = new Request(
            `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/${materialtype === "PDF" ? "pdfupload" : "uploadtest"
            }`,
            {
              method: "POST",
              headers,
              mode: "cors",
              body: chunk,
            }
          );
          console.log(chunkId, " : ", chunkCount);
          await fetch(req)
            .then((res) => res.json())
            .then((data) => {
              if (data.success) {
                if (chunkId + 1 > chunkCount) {
                  setLoading(false);
                  setAddMaterial(false);
                  setProgress(0);
                }
              } else {
                console.log(data.message);
                setLoading(false);
              }
            })
            .catch((err) => console.log(err.message));

          setProgress(Math.round((chunkId * 100) / chunkCount, 0));
        }
      };
      fileReader.readAsArrayBuffer(uploadfile);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  return (
    <Col style={{ marginTop: "20px" }}>
      <Form onSubmit={handleSubmit(addNewMaterial)}>
        <h1>{language === "english" ? "Add a content" : "কনটেন্ট যোগ করুন"}</h1>
        <Form.Group style={{ display: "none" }}>
          <Form.Label>adminid</Form.Label>
          <Form.Control
            name="adminid"
            defaultValue={user.id}
            ref={register({
              required: "You must select a material type",
            })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {language === "english" ? "Content name" : "কন্টেন্টের নাম"}
          </Form.Label>
          <Form.Control
            name="name"
            ref={register({
              required: "You must give a material name",
            })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {language === "english"
              ? "Select content type"
              : "কনটেন্টের ধরণ বাছাই করুন"}
          </Form.Label>
          <Form.Control
            as="select"
            name="materialtype"
            ref={register({
              required: "You must select a material type",
            })}
          >
            <option>Text</option>
            <option>Image</option>
            <option>Video</option>
            {/* <option>PDF</option> */}
          </Form.Control>
        </Form.Group>
        {materialtype === "Text" && (
          <Form.Group>
            <Form.Label>
              {language === "english"
                ? "Enter Text Here"
                : "এখানে টেক্সট লিখুন"}
            </Form.Label>
            <Form.Control as="textarea" name="material" ref={register} />
          </Form.Group>
        )}
        {materialtype === "Image" && (
          <Form.Group>
            <Form.Label>
              {language === "english"
                ? "Select Image Here"
                : "ছবি সিলেক্ট করুন"}
            </Form.Label>
            <Form.File
              accept="image/*"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Form.Group>
        )}
        {materialtype === "Video" && (
          <Form.Group>
            <Form.Label>
              {language === "english"
                ? "Select Video Here"
                : "ভিডিও সিলেক্ট করুন"}
            </Form.Label>
            <Form.File
              accept="video/mp4,video/x-m4v,video/*"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Form.Group>
        )}
        {materialtype === "PDF" && (
          <Form.Group>
            <Form.Label>
              {language === "english"
                ? "Select PDF file Here"
                : "পিডিএফ সিলেক্ট করুন"}
            </Form.Label>
            <Form.File
              accept="application/pdf"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Form.Group>
        )}
        {loading && (
          <Form.Group className="d-flex flex-row justify-content-between">
            <ProgressBar
              style={{ width: "100%" }}
              label={`${progress}%`}
              animated
              now={progress}
            />{" "}
            {/* <Form.Text>{progress} %</Form.Text> */}
          </Form.Group>
        )}
        <Button
          type="button"
          variant="secondary"
          onClick={() => setAddMaterial((up) => !up)}
        >
          {language === "english" ? "Cancel" : "বাতিল"}
        </Button>

        <Button disabled={loading} type="submit" variant="primary">
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>{language === "english" ? "Upload" : "আপলোড"}</>
          )}
        </Button>
      </Form>
    </Col>
  );
};

export default AddMaterial;

import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Col, Spinner, Table, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Pagination from "./Pagination";
export default function Notices() {
  const [query, setQuery] = useState("");
  const { user } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.language);
  const [loading, setLoading] = useState(false);
  const [noticeSets, setNoticeSets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const history = useHistory();
  const getNoticeSets = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `/admin/getpaginatednoticesets/${user.organization}?page=${page}`,
        );
        if (data.success) {
          setNoticeSets(data.noticesets);
          setTotalPages(data.totalPages);
          setCurrentPage(data.currentPage);
        } else {
          console.log(data.message);
        }
      } catch (error) {
        console.log(error.message);
      }
      setLoading(false);
    },
    [user.organization],
  );

  useEffect(() => {
    getNoticeSets();
  }, [getNoticeSets]);
  return (
    <Col style={{ marginTop: "20px" }}>
      <div className="my-3 d-flex flex-row flex-wrap">
        <h2 className="mr-auto">
          {language === "english" ? "All notices" : "সব নোটিশ"}
        </h2>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={getNoticeSets}
        />
        <div className="d-flex flex-row flex-wrap align-items-center">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              type="search"
              placeholder={language === "english" ? "Search here" : "খোঁজ করুন"}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Form>
          <Button
            variant="primary"
            className="ml-1"
            onClick={() => history.push("/addnoticesets")}
          >
            {/* {language === "english"
              ? "Add a new Notice Set"
              : "নতুন নোটিশ যোগ করুন"} */}
            {language === "english" ? "Add new" : "যোগ করুন"}
          </Button>
        </div>
      </div>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Table responsive striped>
          <thead>
            <tr>
              <th>#</th>
              <th>{language === "english" ? "Name" : "নাম"}</th>
              <th>{language === "english" ? "Type" : "ধরণ"}</th>
              <th>
                {language === "english" ? "No. of Materials" : "কনটেন্ট সংখ্যা"}
              </th>
              <th>{language === "english" ? "Date" : "তারিখ"}</th>
              <th>{language === "english" ? "Action" : "অ্যাকশন"}</th>
            </tr>
          </thead>
          <tbody>
            {noticeSets
              ?.filter((n) => {
                return n?.name?.toLowerCase().includes(query?.toLowerCase());
              })
              ?.map((noticeset, id) => (
                <tr key={id}>
                  <td>{id + 1}</td>
                  <td>{noticeset?.name}</td>
                  <td>{noticeset?.viewtype}</td>
                  <td>{noticeset?.materials?.length}</td>
                  <td> {noticeset.createdAt
                    ? new Date(noticeset.createdAt).toLocaleDateString()
                    : "-"}</td>
                  <td className="d-flex flex-row">
                    <button
                      id={noticeset._id}
                      onClick={(e) =>
                        history.push(`/editnoticeset/${e.target.id}`)
                      }
                      className="btn btn-small"
                    >
                      <FaEdit />
                      {/* {language === "english" ? "View/Edit" : "দেখুন / এডিট করুন"} */}
                    </button>
                    <button
                      id={noticeset._id}
                      // onClick={deleteMatrial}
                      className="btn btn-small btn-danger"
                    >
                      <FaTrashAlt />
                      {/* {language === "english" ? "Delete" : "ডিলিট"} */}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </Col>
  );
}

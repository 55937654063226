import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Form, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";

export const SplitSet = ({ noticeset, name, register, part }) => {
  const [noticeSets, setNoticeSets] = useState([]);
  const [noticeSetQuery, setNoticeSetQuery] = useState("");
  const [defaultNoticeset, setDefaultNoticeset] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.language);
  const getNoticeSets = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/admin/getnoticesets/${user.organization}`
      );
      if (data.success) {
        setNoticeSets(data.noticesets);
      }
    } catch (error) {
      console.log(error.message);
    }
    setLoading(false);
  }, [user.organization]);
  useEffect(() => {
    getNoticeSets();
  }, [getNoticeSets]);
  useEffect(() => {
    if (
      !loading &&
      noticeset &&
      noticeset.length !== 0 &&
      noticeset.length === part + 1
    ) {
      setDefaultNoticeset(noticeset[part]._id);
      console.log(noticeset[part]._id);
    } else {
      console.log(part);
    }
  }, [noticeset, part, loading]);
  return (
    <Form.Group
      className="my-3 p-3"
      style={{ border: "1px solid grey", height: "100%" }}
    >
      <Form.Label>
        {language === "english" ? "Select a Notice" : "নোটিশ বাছাই করুন"}
      </Form.Label>
      <Form.Control
        onChange={(e) => setNoticeSetQuery(e.target.value)}
        placeholder={
          language === "english" ? "Search notice here" : "এখানে নোটিশ খুজুন"
        }
      />
      {/* <p>Selected notice : {defaultNoticeset?.name}</p> */}
      {!loading && (
        <FormControl
          as="select"
          // className="form-control"
          name={name}
          value={defaultNoticeset._id}
          ref={register({
            required: "You must select a noticeset",
          })}
        >
          {noticeSets
            ?.filter((ns) => {
              return (
                ns.name
                  ?.toLowerCase()
                  .includes(noticeSetQuery?.toLowerCase()) || !noticeSetQuery
              );
            })
            .map((ns, id) => (
              <option key={id} value={ns._id}>
                {ns.name}
                {/* {console.log(ns._id)} */}
              </option>
            ))}
        </FormControl>
      )}
    </Form.Group>
  );
};

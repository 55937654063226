import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

const DiskSpace = () => {
  const { user } = useSelector((state) => state.auth);
  const [storageUsage, setStorageUsage] = useState();
  const getStorageUsage = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `https://assetupload.coursebee.com/getusage`,
        // `http://localhost:5050/getusage`,

        {
          path: `kernel/noticebee/${user.organization}`,
        }
      );
      if (data.success) {
        setStorageUsage(data.usage);
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [user.organization]);
  useEffect(() => {
    getStorageUsage();
  }, [getStorageUsage]);
  return (
    <Card className="col-12 m-3">
      <Card.Body>
        <Card.Title>Disk Usage</Card.Title>
        <meter
          style={{ width: "100%", height: "40px", borderRadius: "50%" }}
          max="2147483648"
          min="0.0"
          value={storageUsage?.occupied}
          high="1610612736"
          low="536870912"
          optimum="1073741824"
          title={`${Math.trunc(storageUsage?.occupied / 1048576)} MB`}
        ></meter>
        <Card.Subtitle>
          Used {Math.trunc(storageUsage?.occupied / 1048576)} MB out of 2048 MB
          ({storageUsage?.objects} contents stored)
        </Card.Subtitle>
      </Card.Body>
    </Card>
  );
};

export default DiskSpace;

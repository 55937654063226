import { applyMiddleware, combineReducers, createStore } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import thunk from "redux-thunk";
import languageReducer from "./languageReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  errors: errorReducer,
  language: languageReducer,
});

const store = createStore(rootReducer, {}, applyMiddleware(thunk));

export default store;

import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Materials from "../components/DashComponents/Materials";
import AddMaterial from "./AddMaterial";

export default function Material() {
  const [addMaterial, setAddMaterial] = useState(false);

  // const upload = async (uploadfile, values) => {
  //   if (!file) {
  //     alert("Select a file to upload first");
  //     setLoading(false);
  //     return;
  //   }
  //   try {
  //     // const uploaddata = new FormData()
  //     // uploaddata.append('file', uploadfile)
  //     // uploaddata.append('values', JSON.stringify(values))
  //     // const { data } = await axios.post(`${REACT_APP_NOT_AXIOS_BASE_URL}/admin/upload`, uploaddata)
  //     // console.log(data.message)
  //     values.orgid = user.organization;

  //     let headers = new Headers();
  //     headers.append("Accept", "Application/JSON");
  //     headers.append("Access-Control-Allow-Origin", "*");

  //     let formdata = new FormData();

  //     formdata.append("values", JSON.stringify(values));
  //     formdata.append("file", uploadfile);

  //     let req = new Request(
  //       `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/${
  //         materialtype === "PDF" ? "pdfupload" : "upload"
  //       }`,
  //       {
  //         method: "POST",
  //         headers,
  //         mode: "cors",
  //         body: formdata,
  //       }
  //     );

  //     fetch(req)
  //       .then((res) => res.json())
  //       .then((data) => {
  //         if (data.success) {
  //           setLoading(false);
  //           setAddMaterial(false);
  //         } else {
  //           alert(data.message);
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => alert(err.message));

  //     //   if (data.success) {
  //     //     setLoading(false);
  //     //     setAddMaterial(false);
  //     //   } else {
  //     //     setLoading(false);
  //     //   }
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error.message);
  //   }
  // };

  return (
    <Container>
      <Row lg={1} md={1} sm={1} xl={1} xs={1}>
        {addMaterial ? (
          <AddMaterial setAddMaterial={(sm) => setAddMaterial(sm)} />
        ) : (
          <Col>
            <Materials setAddMaterial={(sm) => setAddMaterial(sm)} />
          </Col>
        )}
      </Row>
    </Container>
  );
}

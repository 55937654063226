import './App.scss';
import { Switch } from 'react-router-dom';
import RouteBody from "./router";
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <Switch>
        <RouteBody />
      </Switch>
    </ErrorBoundary>
  );
}

export default App;

import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Image,
  Table,
  Spinner,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import Pagination from "./Pagination";
import axios from "axios";
import { /*FaEdit,*/ FaTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
// import MaterialModal from './MaterialModal'
const { REACT_APP_NOT_AXIOS_BASE_URL } = process.env;

export default function Materials({ setAddMaterial }) {
  const [materials, setMaterials] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editMaterialModal, setEditMaterialModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.language);

  const handleClose = () => setEditMaterialModal(false);
  // const handleShow = () => setEditMaterialModal(true);

  const getMaterials = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/getpaginatedmaterials/${user.id}?page=${page}`,
        );
        if (data.success) {
          setMaterials(data.materials);
          setTotalPages(data.totalPages);
          setCurrentPage(data.currentPage);
        } else {
          console.log(data.message);
        }
      } catch (error) {
        console.log(error.message);
      }
      setLoading(false);
    },
    [user.id],
  );

  const editMaterial = async (e) => {
    e.preventDefault();
    try {
      handleClose();
    } catch (error) {}
  };

  const deleteMatrial = async (e) => {
    try {
      console.log(e.target.id);
      const { data } = await axios.post(
        `${REACT_APP_NOT_AXIOS_BASE_URL}/admin/deleteasset/${e.target.id}`,
      );
      if (data.success) {
        getMaterials();
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getMaterials();
  }, [getMaterials]);
  return (
    <>
      <Row lg={1} md={1} sm={1} xl={1} xs={1}>
        <Col style={{ marginTop: "20px" }}></Col>
        <Col style={{ marginTop: "20px" }}>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between align-items-center wrap">
                <h1>
                  {language === "english" ? "All contents" : "সব কনটেন্ট"}
                </h1>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={getMaterials}
                />
                <div className="d-flex flex-row flex-wrap ">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Form.Control
                      type="search"
                      placeholder={
                        language === "english" ? "Search here" : "খোঁজ করুন"
                      }
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </Form>
                  <Button
                    size="sm"
                    className="ml-1"
                    onClick={() => setAddMaterial((sm) => !sm)}
                  >
                    {language === "english"
                      ? "Add a content"
                      : "কনটেন্ট যোগ করুন"}
                  </Button>
                </div>
              </div>
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{language === "english" ? "Name" : "নাম"}</th>
                    <th>
                      {language === "english"
                        ? "No. of Contents"
                        : "কনটেন্ট সংখ্যা"}
                    </th>
                    <th>{language === "english" ? "Type" : "ধরণ"}</th>
                    <th>{language === "english" ? "Date" : "তারিখ"}</th>
                    <th>{language === "english" ? "Action" : "অ্যাকশন"}</th>
                  </tr>
                </thead>
                <tbody>
                  {materials
                    .filter((m) => {
                      return m?.name
                        ?.toLowerCase()
                        .includes(query?.toLowerCase());
                    })
                    .map((material, id) => (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td>{material.name}</td>
                        <td>
                          {material.materialtype === "Image" ? (
                            <Image
                              src={material.material}
                              style={{ maxHeight: "40px" }}
                              rounded
                            />
                          ) : (
                            <>
                              {material.materialtype === "Video" ? (
                                <a
                                  href={material.material}
                                  style={{
                                    maxHeight: "40px",
                                    overflow: "hidden",
                                  }}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {language === "english"
                                    ? "See video"
                                    : "ভিডিও দেখুন"}
                                </a>
                              ) : (
                                <p
                                  style={{
                                    maxHeight: "40px",
                                    overflow: "hidden",
                                  }}
                                >
                                  {material.material}
                                </p>
                              )}
                            </>
                          )}
                        </td>
                        <td>{material.materialtype}</td>
                        <td>
                          {material.createdAt
                            ? new Date(material.createdAt).toLocaleDateString()
                            : "-"}
                        </td>
                        <td className="d-flex flex-row">
                          {/* <button
                            value={material._id}
                            onClick={handleShow}
                            className="btn btn-small btn-outline-primary"
                          >
                            <FaEdit />
                          </button>{" "} */}
                          {/* {language === "english" ? "Edit" : "ডিলিট"} */}
                          {material._id && (
                            <button
                              id={material._id}
                              onClick={deleteMatrial}
                              className="btn btn-small btn-outline-danger"
                            >
                              <FaTrashAlt />
                              {/* {language === "english" ? "Delete" : "ডিলিট"} */}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </>
          )}
        </Col>
      </Row>
      <Modal show={editMaterialModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={editMaterial}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

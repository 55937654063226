import React from "react";
import { Route } from "react-router-dom";
import NavBar from "./components/Layouts/NavBar";
import DashBoard from "./pages/DashBoard";
import Login from "./pages/Login";
// import Register from "./pages/Register";
import AdminRoute from "./ProtectedRoutes/AdminRoute";
import Material from "./pages/Material";
import Noticeboard from "./pages/Noticeboard";
import EditNoticeBoard from "./pages/EditNoticeBoard";
import EditNoticeSet from "./pages/EditNoticeSet";
import Setting from "./pages/Setting";
import { AddNoticeSet } from "./pages/AddNoticeSet";
import LanguageSwitch from "./components/Layouts/LanguageSwitch";

function RouteBody() {
  return (
    <div className="mainBody" style={{ position: "relative", height: "100vh" }}>
      <NavBar />
      <AdminRoute exact path="/" component={DashBoard} />
      <Route path="/login" component={Login} />
      {/* <Route path="/register" component={Register} /> */}
      <AdminRoute path="/materials" component={Material} />
      <AdminRoute path="/notices" component={Noticeboard} />
      <AdminRoute path="/addnoticesets" component={AddNoticeSet} />
      <AdminRoute path="/editnoticeset/:id" component={EditNoticeSet} />
      <AdminRoute path="/noticeboard/:id" component={EditNoticeBoard} />
      <AdminRoute path="/settings" component={Setting} />
      <LanguageSwitch />
    </div>
  );
}

export default RouteBody;
